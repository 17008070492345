<template>
  <footer class="app-footer">
    <div class="app-footer__container">
      <footer-top-box />
    </div>
    <footer-bottom-box />
  </footer>
</template>

<script>
import FooterBottomBox from './FooterBottomBox';
import FooterTopBox from './FooterTopBox';

export default {
  components: {
    FooterTopBox,
    FooterBottomBox,
  },
};
</script>

<style lang="scss">
.app-footer {
  color: $footer-color;
  background-color: $footer-bg;

  &__container {
    @include container;
  }
}
</style>
