<template>
  <router-link
    v-if="isShowButton"
    :to="to"
    class="menu-btn menu-btn--faq"
  >
    <span class="menu-btn__icon icon icon--search" />
    <span class="menu-btn__title">
      Häufige Fragen
    </span>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      to: { name: 'faq' },
      routesIncludesButton: ['!faq'],
    };
  },
  computed: {
    isShowButton() {
      const routeName = this.$route.name;
      return this.routesIncludesButton.includes(routeName) || !this.routesIncludesButton.includes(`!${routeName}`);
    },
  },
};
</script>

<style lang="scss">
</style>
