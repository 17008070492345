<template>
  <div
    class="app-menu"
  >
    <button
      v-if="currentRouteName != 'cart'"
      class="app-menu__icon app-menu__icon--cart"
      :disabled="!cartTotalData.markersCount"
      @click="goToCart"
    >
      <span v-if="cartTotalData.markersCount">{{ cartTotalData.markersCount }}</span>
    </button>
    <button
      v-show="openBtnShow"
      class="app-menu__icon app-menu__icon--burger-open"
      @click="openBurger"
    >
      <span /><span /><span />
    </button>
    <button
      v-show="closeBtnShow"
      class="app-menu__icon app-menu__icon--burger-close"
      @click="closeBurger"
    >
      <span /><span />
    </button>

    <div
      id="app-menu__items"
      class="app-menu__items"
    >
      <btn-cart class="app-menu__btn" />
      <btn-result
        v-if="isShowResultsButton"
        class="app-menu__btn"
      />
      <btn-criteria class="app-menu__btn" />
      <btn-faq class="app-menu__btn" />
      <btn-back class="app-menu__btn" />
    </div>
  </div>
</template>

<script>
import BtnCart from './components/btn-cart';
import BtnCriteria from './components/btn-criteria';
import BtnResult from './components/btn-result';
import BtnFaq from './components/btn-faq';
import BtnBack from './components/btn-back';

export default {
  components: {
    BtnCart,
    BtnResult,
    BtnCriteria,
    BtnFaq,
    BtnBack,
  },
  data() {
    return {
      burgerMenu: null,
      burgerMenuActiveClass: 'app-menu__items--active',
      openBtnShow: true,
      closeBtnShow: false,
      closeAnimationProps: [
        { transform: 'none' },
        { transform: 'translateX(100%)' },
      ],
      openAnimationProps: [
        { transform: 'translateX(100%)' },
        { transform: 'none' },
      ],
    };
  },
  computed: {
    isShowResultsButton() {
      return this.$store.getters.isCurrentCriteriaValid;
    },
    cartTotalData() {
      return this.$store.getters.cartTotalData;
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    currentRouteName: 'closeBurger',
  },
  mounted() {
    this.burgerMenu = document.querySelector('#app-menu__items');
  },
  methods: {
    goToCart() {
      this.$router.push({ name: 'cart' });
    },
    openBurger() {
      if (this.openBtnShow) {
        this.burgerMenu.classList.add(this.burgerMenuActiveClass);
        this.burgerAnimation(this.openAnimationProps).onfinish = () => {
          document.body.style.overflowX = 'auto';
        };
      }
    },
    closeBurger() {
      if (this.closeBtnShow) {
        this.burgerAnimation(this.closeAnimationProps).onfinish = () => {
          this.burgerMenu.classList.remove(this.burgerMenuActiveClass);
          document.body.style.overflowX = 'auto';
        };
      }
    },
    burgerAnimation(animationProps) {
      document.body.style.overflowX = 'hidden';
      this.changeBtns();
      return this.burgerMenu.animate(animationProps, { duration: 300 });
    },
    changeBtns() {
      this.openBtnShow = !this.openBtnShow;
      this.closeBtnShow = !this.closeBtnShow;
    },
  },
};
</script>

<style lang="scss">
.app-menu {
  $root: &;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  &__icon {
    font-size: 30px;
    color: $menu-icon-color;
    cursor: pointer;

    &:disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.4;
    }

    &--cart {
      @include icon(cart);

      position: relative;
      margin-right: 12px;

      span {
        font-family: $font-family-primary;
        display: block;
        position: absolute;
        font-size: 0.5em; //16px;
        line-height: 1; //16px;
        font-weight: 400;
        padding: 0.12em 0.42em; //2px 7px;
        top: -0.3em; //5px;
        left: -0.3em; //5px;
        color: $badge-color;
        background-color: $badge-bg-color;
        border-radius: 50%;
      }
    }

    &--burger-open {
      @include burger-open-btn;
    }

    &--burger-close {
      @include burger-close-btn;
    }

    @include media-bp-down(tab) {
      display: none;
    }
  }

  &__items {
    display: flex;
    justify-content: flex-end;

    @include media-bp(tab) {
      display: none;
      padding: 3px;
      background-color: $panel-bg;
      position: absolute;
      top: 75px;
      right: 0;
      box-shadow: $panel-box-shadow;
      border-radius: 5px;
      z-index: 999;
      transform: translateX(-500px);

      &--active {
        display: flex;
        transform: none;
      }
    }

  }

  &__btn {
    padding: 5px 10px 3px;
    width: 110px;
    height: 80px;
    font-size: 24px;
    color: $menu-button-color;

    background-color: $menu-button-bg-color;

    &:nth-child(1) {
      background-color: $menu-button-bg-color-child-1;
    }
    &:nth-child(2) {
      background-color: $menu-button-bg-color-child-2;
    }
    &:nth-child(3) {
      background-color: $menu-button-bg-color-child-3;
    }

    &.menu-btn {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;

      &[disabled] {
        cursor: default;
      }
    }

    .menu-btn {
      &__title {
        flex-grow: 1;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin-top: 4px;
      }

      &__badge {
        display: block;
        position: absolute;
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
        padding: 2px 7px;
        top: 5px;
        right: 5px;
        color: $badge-color;
        background-color: $badge-bg-color;
        border-radius: 50%;
      }

      &__info {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 7px;
        font-size: 10px;
        font-weight: 700;

        &-item {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
