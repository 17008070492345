<template>
  <router-link
    v-if="isShowButton"
    :to="to"
    class="menu-btn menu-btn--criteria"
  >
    <span class="menu-btn__icon icon icon--adjustments" />
    <span class="menu-btn__title">
      Kriterien ändern
    </span>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      to: { name: 'criteria' },
      routesIncludesButton: ['!criteria', '!faq'],
    };
  },
  computed: {
    isShowButton() {
      const routeName = this.$route.name;
      return this.routesIncludesButton.includes(routeName) || !this.routesIncludesButton.includes(`!${routeName}`);
    },
  },
};
</script>

<style lang="scss">
</style>
