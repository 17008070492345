<template>
  <div class="main-layout">
    <app-header class="main-layout__header" />
    <div class="main-layout__container">
      <app-panel>
        <router-view />
      </app-panel>
    </div>
    <app-footer class="main-layout__footer" />
  </div>
</template>

<script>
import store from '@/store';
import eventbus from '@/utils/eventbus';

import AppFooter from '@/components/app-footer';
import AppHeader from '@/components/app-header';
import AppPanel from '@/components/app-panel';

export default {
  components: {
    AppHeader,
    AppFooter,
    AppPanel,
  },

  data() {
    return {
      testData: 'test',
    };
  },

  methods: {
    checkSavedCart() {
      const totalCartDataCount = Object.keys(this.$store.getters.cartData).length;
      const isCartRoute = this.$route.name === 'cart';

      if ((totalCartDataCount) && !isCartRoute) {
        const subStr = totalCartDataCount > 1
          ? `sind ${totalCartDataCount} Medien`
          : 'ist 1 Medium';

        const body = `Es ${subStr} in Ihrem Warenkorb.

Möchten Sie Ihre alte Selektion fortführen?

Wir werden diese dann automatisch auf Aktualität prüfen.`;

        eventbus.$emit('open-message', {
          body,
          buttons: {
            ja: () => this.$store.dispatch('processCartData'),
            nein: () => this.$store.dispatch('clearCart'),
          },
        });
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    if (to.params.cartCode) {
      store.dispatch('updateCartDataFromCode', to.params.cartCode)
        .finally(() => {
          next({ ...to, params: {} });
        });
    } else {
      store.dispatch('setCriteriaFromQuery', to.query)
        .finally(() => next((vm) => vm.checkSavedCart()));
    }
  },
};
</script>

<style lang="scss">
.main-layout {
  height: 100vh;

  display: flex;
  flex-direction: column;

  @include media-bp(mob) {
    min-height: 100vh;
    height: auto;
  }

  &__container {
    @include container;

    flex: 1 1 auto;
    overflow: hidden;
  }
}
</style>
