<template>
  <main class="app-panel">
    <header class="app-panel__header">
      <h2 class="app-panel__title">
        {{ title }}
      </h2>
    </header>
    <section class="app-panel__content">
      <slot />
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      panelTitles: {
        criteria: 'Suchkriterien',
        result: 'Ergebnisse',
        cart: 'Warenkorb',
      },
    };
  },
  computed: {
    title() {
      return this.panelTitles[this.$route.name] || this.$route.name;
    },
  },
};
</script>

<style lang="scss">
.app-panel {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 20px 0 8px;

  @include media-bp(tab) {
    padding-top: 12px;
  }

  @include media-bp(mob) {
    padding-top: 8px;
  }

  &__header {
    color: $panel-header-color;
    background-color: $panel-header-bg-color;

    @include outfly;
  }

  &__title {
    padding: 12px 15px;

    font-size: $panel-header-title-font-size;
    line-height: $panel-header-title-line-height;
    font-weight: $panel-header-title-font-weight;
    text-transform: $panel-header-title-text-transform;
  }

  &__content {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    border: $panel-border;

    @include media-bp(mob) {
      flex-basis: 90vh;
    }
  }
}
</style>
