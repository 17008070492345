<template>
  <div class="app-footer__bottom-box">
    powered by&nbsp;
    <a
      href="https://www.twentyseconds.de"
      class="app-footer__powered-by-link"
    >
      twenty seconds - Projekte im Yes-Modus
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.app-footer__bottom-box {
  $root: '.app-footer';

  display: flex;
  justify-content: center;
  background-color: $footer-bottom-bg;
  color: $footer-bottom-color;
  font-size: 11px;
  line-height: 1.05;
  padding: 2px 0;
  z-index: 999;

  #{$root}__powered-by-link {
    color: $footer-bottom-color;
  }
}
</style>
