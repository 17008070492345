<template>
  <div class="app-logo-container">
    <router-link
      :to="to"
      class="app-logo"
    >
      <img
        src="@/assets/img/logo_omni.svg"
        alt="LOGO"
        class="app-logo__img"
      >
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    to() {
      return { ...this.$route, name: 'criteria' };
    },
  },
};
</script>

<style lang="scss">
.app-logo__img {
  display: block;
  background-repeat: no-repeat;
  max-width: 236px;
  height: 56px;

  @include media-bp(tab) {
    height: 40px;
  }

  @include media-bp(mob) {
    height: 30px;
  }
}
</style>
