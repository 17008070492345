<template>
  <router-link
    v-if="isShowButton"
    :to="to"
    class="menu-btn menu-btn--back"
  >
    <span class="menu-btn__icon icon icon--cros" />
    <span class="menu-btn__title">
      Zurück
    </span>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      to: { name: 'criteria' },
      routesIncludesButton: ['faq'],
    };
  },
  computed: {
    isShowButton() {
      const routeName = this.$route.name;
      if (!this.routesIncludesButton?.length) return true;

      const hasNegative = this.routesIncludesButton.some((el) => el[0] === '!');

      if (this.routesIncludesButton.includes(routeName)) return true;
      if (hasNegative && !this.routesIncludesButton.includes(`!${routeName}`)) return true;

      return false;
    },
  },

  watch: {
    $route(newVal, oldVal) {
      if (!oldVal || oldVal.name === newVal.name) {
        this.to = { name: 'criteria' };
      } else {
        this.to = oldVal;
      }
    },
  },
};
</script>

<style lang="scss">
</style>
