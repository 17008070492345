<template>
  <header class="app-header">
    <app-logo class="app-header__logo" />
    <div class="app-header__adv">
      <span>Ihr Partner für Außenwerbung</span>
      <span>INDIVIDUELL | WIRKSAM | PRÄSENT</span>
    </div>
    <app-menu class="app-header__menu" />
  </header>
</template>

<script>
import AppLogo from '@/components/app-logo';
import AppMenu from '@/components/app-menu';

export default {
  components: {
    AppLogo,
    AppMenu,
  },
};
</script>

<style lang="scss">
.app-header {
  $root: &;

  @include container;
  position: relative;
  display: flex;
  justify-content: space-between;

  @include media-bp(tab) {
    flex-wrap: wrap;
  }

  &__logo {
    align-self: center;
    flex: 0 0 auto;
    padding-right: 20px;
  }

  &__adv {
    align-self: center;
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    font-weight: $header-adv-font-weight;
    font-size: $header-adv-font-size;
    line-height: $header-adv-line-height;
    color: $header-adv-color;

    span {
      &::after {
        content: "\00a0";
      }
    }

    @include media-bp(tab) {
      order: -1;
      flex-basis: 100%;
      font-size: 14px;
      // margin-bottom: 8px;

      justify-content: space-between;

      span {
        &:nth-child(3) {
          flex-basis: auto;
        }
      }
    }

    @include media-bp(mob) {
      span {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }

  &__menu {
    flex: 0 0 auto;
    padding-left: 20px;
  }
}
</style>
