<template>
  <router-link
    v-if="isShowButton"
    :to="to"
    class="menu-btn menu-btn--cart"
    :disabled="!cartTotalData.markersCount"
  >
    <span class="menu-btn__icon icon icon--cart" />
    <span class="menu-btn__badge">
      {{ cartTotalData.markersCount }}
    </span>
    <ul class="menu-btn__info">
      <li class="menu-btn__info-item">
        <span>Netto:</span>
        <span>{{ cartTotalData.totalNetto | euro }}</span>
      </li>
      <li class="menu-btn__info-item">
        <span>MwSt.:</span>
        <span>{{ cartTotalData.totalMwst | euro }}</span>
      </li>
      <li class="menu-btn__info-item">
        <span>Gesamt:</span>
        <span>{{ cartTotalData.total | euro }}</span>
      </li>
    </ul>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      routesIncludesButton: ['!cart', '!faq'],
    };
  },
  computed: {
    to() {
      return this.cartTotalData.markersCount ? { name: 'cart' } : {};
    },
    isShowButton() {
      const routeName = this.$route.name;
      return this.routesIncludesButton.includes(routeName) || !this.routesIncludesButton.includes(`!${routeName}`);
    },
    cartTotalData() {
      return this.$store.getters.cartTotalData;
    },
  },
};
</script>

<style lang="scss">
</style>
