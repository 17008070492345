<template>
  <div class="app-footer__top-box">
    <a
      href="https://www.moplak.de/"
      class="app-footer__company-link"
    >
      &copy; MOPLAK Medien
      Service GmbH
    </a>
    <nav class="app-footer__nav">
      <a
        href="https://www.moplak.de/unternehmen/impressum"
        class="app-footer__nav-item"
      >
        Impressum
      </a>
      <a
        href="https://www.moplak.de/unternehmen/datenschutzerklaerung"
        class="app-footer__nav-item"
      >
        Datenschutzerklärung
      </a>
      <a
        href="https://www.moplak.de/unternehmen/disclaimer"
        class="app-footer__nav-item"
      >
        Disclaimer
      </a>
      <a
        href="https://www.moplak.de/Moplak_AGB.pdf"
        class="app-footer__nav-item"
      >
        AGB
      </a>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.app-footer__top-box {
  $root: '.app-footer';

  display: flex;
  justify-content: space-between;
  font-size: 14px;
  flex-wrap: wrap;

  padding-top: 4px;
  padding-bottom: 4px;

  #{$root}__company-link {
    flex: 1 0 auto;
    color: $footer-nav-color;
    line-height: 14px;
    margin-top: 4px;

    @include media-bp(tab) {
      flex: 0 0 100%;
      margin-bottom: 8px;
      text-align: center;
    }

    // @media (max-width: 600px) {
    //   flex: 0 0 100%;
    //   text-align: center;
    // }
  }

  #{$root}__nav {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include media-bp(tab) {
      flex: 1 1 100%;
      justify-content: center;
    }

    #{$root}__nav-item {
      line-height: 1.4;
      color: $footer-nav-color;
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: none;
      }

      &:not(&:first-child) {
        margin-left: 16px;
      }

      @include media-bp(mob) {
        flex: 0 0 50%;
        text-align: center;
        padding: 4px 0;

        &:not(&:first-child) {
          margin-left: 0px;
        }
      }

      // @media (max-width: 680px) {
      //   margin: 0;
      //   flex: 0 0 50%;
      //   text-align: center;
      // }

      // @media (max-width: 600px) {
      //   padding: 5px 0;
      // }
    }
  }
}
</style>
